<template>
  <div
    ref="plyr"
    :data-plyr-provider="provider"
    :data-plyr-embed-id="url"
  ></div>
</template>

<script>
import { defineComponent } from 'vue';
import 'plyr/dist/plyr.css';
import Plyr from 'plyr';

export default defineComponent({
  name: 'Plyr',
  props: {
    provider: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    controls: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    player: null,
  }),
  methods: {
    pause() {
      this.player.pause();
    },
  },
  mounted() {
    this.player = new Plyr(this.$refs.plyr, {
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
      },
      ...this.controls,
    });

    this.player.on('ready', () => {
      setTimeout(() => {
        this.$emit('ready');
      }, 200);
    });
  },
});
</script>
