<template>
  <div class="video-player" :class="{ thumbnail }" v-if="ready" v-show="show">
    <Plyr :provider="provider" :url="url" :controls="controls" @ready="show = true" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Plyr from '@/components/plyr/Plyr.vue';

export default defineComponent({
  name: 'VideoPlayer',
  components: {
    Plyr,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mounted: false,
    ready: false,
    show: false,
  }),
  computed: {
    provider() {
      if (/youtu.be\b/g.test(this.url)) return 'youtube';
      if (/youtube.com\b/g.test(this.url)) return 'youtube';
      if (/vimeo.com\b/g.test(this.url)) return 'vimeo';
      return '';
    },
    controls() {
      return this.thumbnail
        ? { controls: [] }
        : { controls: ['play', 'progress', 'mute', 'volume', 'fullscreen'] };
    },
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.ready = false;
        this.$nextTick(() => {
          this.ready = true;
        });
      },
    },
  },
});
</script>

<style lang="scss">
.video-player {
  &.thumbnail {
    border: none !important;
    pointer-events: none;

    .plyr {
      min-width: 480px;
    }
  }
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #eb3d47;
}
.plyr--full-ui input[type="range"] {
  color: #eb3d47;
}
</style>
